

function Loading(){
    return(
        <div className="container d-flex justify-content-center align-content-center">
            <div className="spinner-border" role="status">
            </div>
        </div>
    );
}

export default Loading;